import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import SEO from "../components/Layout/SEO";
import Awards from "../components/Sections/Awards";
import FAQ from "../components/Sections/FAQ";

const AboutPage = () => {
  return (
    <>
      <SEO
        title="Awards"
        description="Ina Hranova is a award winner developer based in Bulgaria who specialises in React, React Native,Next.js,WordPress, HTML/CSS for top digital agencies."
      />
      <Awards />
      <FAQ />
    </>
  );
};
export default AboutPage;
