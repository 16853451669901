import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

export default function Awards() {
  const data = useStaticQuery(graphql`
    {
      allAwardsJson {
        nodes {
          name
          link
          label
          website
          img {
            childImageSharp {
              gatsbyImageData(width: 100)
            }
          }
        }
      }
    }
  `);
  //console.log(data);
  const awards = data.allAwardsJson.nodes;
  return (
    <div className="awards my-10 relative z-10 xl:w-4/5 mx-auto">
      <div className="lg:w-3/5 mx-5 lg:mx-10">
        <span className="block mb-4 text-sm font-semibold tracking-widest text-gray-500 dark:text-gray-300 uppercase">
          AWARDS
        </span>
        <h2 className="block mb-4 text-2xl xl:text-4xl sm:font-semibold text-gray-800 dark:text-white mb-10">
          🏆 Sites I was a senior developer got awards such as:
        </h2>
      </div>
      {awards.map((award, x) => (
        <div
          key={x}
          className="awards flex flex-row lg:w-3/5 mx-5 lg:mx-10 items-center my-4 pb-4 border-gray-200 dark:border-gray-800 border-b-2"
        >
          <div className="rounded-lg shadow-xl bg-white h-14 w-14 p-1 mr-5 ">
            <GatsbyImage
              image={getImage(award.img)}
              alt={award.name}
              className="object-cover object-center h-12 w-12"
            />
          </div>
          <div className="position">
            <p className="text-gray-800 mb-0 transition-colors text-scale-0 font-semibold leading-normal dark:text-white">
              {award.name}
            </p>
            <span className="text-sm text-gray-700 xs:text-base block dark:text-gray-500">
              {award.label}
            </span>
            <span className="text-sm text-gray-600 xs:text-base block dark:text-gray-300 leading-5">
              {award.website}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
